<template>
    <layout class="wrapper">
        <div class="container flex">
            <div class="guide-list-left mt20">
                <!-- 面包屑 -->
                <div class="flex pb5 x-left flex-wrap">
                    <div class="color999">您所在的位置：</div>
                    <a-breadcrumb>
                        <a-breadcrumb-item><router-link class="color999" :to="{path:'/'}">首页</router-link></a-breadcrumb-item>
                        <a-breadcrumb-item><router-link class="color999" :to="{path:'/guide-list'}">临床指南</router-link></a-breadcrumb-item>
                        <a-breadcrumb-item>临床指南详情</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
                <a-spin :spinning="loading" tip="Loading...">
                    <!-- 文章详情 -->
                    <div class="guidelist-article mt10 t-l pb20">
                        <p class="fs22 strong color333 row-2">{{ articleItem.title_cn }}</p>
                        <p class="mt15 color333 fs12 row-1"><span class="strong">指南制定者：</span>{{ articleItem.author }}</p>
                        <p class="mt15 color333 fs12 row-1"><span class="strong">出处：</span>{{ articleItem.reference }}</p>
                        <p class="mt15 color333 fs12 row-1"><span class="strong">发布时间：</span>{{ articleItem.publish_date }}</p>
                    </div>
                    <div v-if="articleItem.content" v-html="articleItem.content" class="t-l mt15"></div>
                    <a-empty style="margin-top:100px;" v-if="!articleItem.content && !loading" />
                    <div class="guidelist-download mt40 flex y-center" v-if="articleItem.content">
                        <div class="flex y-center">
                            <div class="download-read-new colorfff" v-if="userInfo.is_member == 1" @click="downloadPDF">
                                点击下载阅读
                            </div>
                            <!-- mini_url+'wPersonal/personal/index/'+committee_id -->
                            <a target="_blank" class="download-read-new colorfff" @click="handleVipEvent"  v-else>成为会员，下载阅读</a>
                        </div>
                        <!--指南详情收藏  -->
                        <span class="ml15 cursor" @click="getExeLikeCollect('1')">
                            <i :class="['med', articleItem.is_collect == 1 ? 'med-shoucang1' : 'med-shoucang']"></i>
                            <span class="ml5">{{ articleItem.is_collect == 1 ? '已收藏' : '收藏' }}</span>
                        </span>
                    </div>
                    <!-- 推广图 -->
                    <img class="guidelist-banner mt35"
                        src="~@/assets/images/adv-banner1.png"
                        alt="">
                </a-spin>
                
                <!-- 评论区 -->
                <!-- <comment-list class="mt40"></comment-list> -->
            </div>
            <div class="guide-list-right mt40">
                <layout-right :config="layoutRight"></layout-right>
            </div>
        </div>


    </layout>
</template>

<script>
import layout from '@/components/layout/index';
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import commentList from '@/components/comment-list.vue';
import acrossMediaItem from '@/components/across-media-item.vue';
import liveItem from '@/components/live-item.vue';
import verticalMediaItem from '@/components/vertical-media-item.vue';
import { mini_url,committee_id } from '@/config';
import { mapState } from 'vuex';
import {login} from '@/utils/jumpPageMethods'
import storage from 'store';
export default {
    name: 'SpecialMaGuideDetail',
    components: {
        layout,layoutRight, commonTitle, commentList,acrossMediaItem,liveItem,verticalMediaItem
    },
    data() {
        return {
            mini_url,
            committee_id,
            layoutRight:{},
            layoutRightConfig: {
                correlationMeet:{ //相关会议
                    show:true, // 是否显示
                    title:'相关会议', // 标题
                    methods:'get',
                    data:{
                        year: new Date().getFullYear(),
                        month: new Date().getMonth()+1,
                        page: 1,
                        pageSize: 3,
                        subject_ids:'',
                        keyword:'',
                    },   // 参数条件
                },
                correlationLive: { //相关直播
                    show: true, // 是否显示
                    methods:'get',
                    title: '相关直播', // 标题
                    data: {
                        tj_token: storage.get('tid'),
                        page: 1,
                        limit: 3,
                        subject_ids: '',
                        keyword:'',
                    },   // 参数条件
                },
                // 相关视频
                correlationVideo: {
                    show: true, // 是否显示
                    methods:'get',
                    title: '相关视频', // 标题
                    data: {
                        page: 1,
                        pageSize: 4,
                        subject_ids: '',
                        keyword:'',
                    },   // 参数条件
                },
            },
            guide_id:this.$route.query.id,
            // 文章详情
            articleItem: {},
            loading:true,
        }
    },
    created() {
        this.pageInit()
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods:{
        handleVipEvent(){
            login().then(()=>{
                window.open(mini_url+'wPersonal/personal/index/'+committee_id, '_blank')
            })
        },
        getGuideDetail(){
            return this.request.post('GuideDetail',{
                guide_id: this.guide_id,
                open_id: "1"
            })
        },
        pageInit(){
            let req = [
                this.getGuideDetail(),
            ]
            Promise.all(req).then(res=>{
                this.loading = true
                // 指南详情
                let subject_ids = res[0].data.subject_ids
                let keywords = res[0].data.keywords
                let layoutRightConfig = this.layoutRightConfig
                layoutRightConfig.correlationMeet.data.subject_ids = subject_ids
                layoutRightConfig.correlationLive.data.subject_ids = subject_ids
                layoutRightConfig.correlationVideo.data.subject_ids = subject_ids
                layoutRightConfig.correlationMeet.data.keyword = keywords
                layoutRightConfig.correlationLive.data.keyword = keywords
                layoutRightConfig.correlationVideo.data.keyword = keywords
                this.layoutRightConfig = layoutRightConfig
                this.layoutRight = this.layoutRightConfig
                this.articleItem = res[0].data
                this.loading = false
            })
        },
        // 点赞收藏
        getExeLikeCollect(operate){
            this.request.post('GetExeLikeCollect',{
                id:this.articleItem.id,
                type:4,
                operate
            }).then(res=>{
                // 收藏1
                if(operate == 1){
                    let is_collect = this.articleItem.is_collect
                    if (is_collect) {
                        is_collect = 0
                    } else {
                        is_collect = 1
                    }
                    this.articleItem.is_collect = is_collect
                }
            })
        },
        downloadPDF(){
            this.request.post('GuidelineDownUrl',{
                id:this.articleItem.id,
                open_id:"1",
            }).then(res=>{
                let pdf_url = res.data
                var downloadPath = pdf_url
                var downloadLink = document.createElement('a')
                downloadLink.style.display = 'none'
                downloadLink.href = downloadPath
                downloadLink.click()
                document.body.removeChild(downloadLink);
            })
        },
    }
}
</script>
<style lang="less" scoped>
.guide-list-left {
    width: 770px;

    .guidelist-breadcrumb {
        width: 300px;
        height: 20px;

        .guide-ant-breadcrumb {
            float: left;
            color: #C1C1C1;
        }
    }

    .guidelist-article {
        width: 100%;
        border-bottom: 1px dashed #D8D8D8;

        p {
            margin-bottom: 0;
        }
    }

    .guidelist-download {
        width: 100%;
        height: 44px;
        cursor: pointer;

        .color-member {
            color: #1E51C9;
        }

        .download-read {
            width: 104px;
            height: 44px;
            background: linear-gradient(to right, #5F96E6, #1E51C9);
            border-radius: 5px;
            text-align: center;
            line-height: 44px;
        }
        .download-read-new {
            height: 40px;
            background: linear-gradient(to right, #5F96E6, #1E51C9);
            border-radius: 5px;
            text-align: center;
            line-height: 40px;
            padding: 0 10px 0 10px;
            &:hover{
                color: #ffffff;
            }
        }
        .med-shoucang1{
            color: #FFA45D;
        }
    }

    .guidelist-banner {
        width: 100%;
        height: 187px;
    }
}

.guide-list-right {
    width: 398px;
}
@media screen and (max-width:768px){
    .container{
        display: block;
        padding: 0 10px;
    }
    .guide-list-left{
        width: 100%;
        .guidelist-banner{
            height: auto;
            margin-top: 20px;
        }
    }
    .guide-list-right{
        width: 100%;
    }
}
</style>
